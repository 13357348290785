/* eslint-disable max-statements */
import React, { useContext, useEffect, useState } from 'react'
import Availability from './availability'
import Button from './../button'
import Dialog from './../dialog'
import FieldNumber from './../field/number'
import FieldTextArea from './../field/textarea'
import DialogContext from './../../context/dialog'
import LogForm from './logForm';

const getLog = (day, slot) => day.log[0] ? day.log[0][slot] : null;
const getLogId = day => day.log[0] ? day.log[0]['id'] : null;

const SvgIcon = ({ label, children }) => (
  <svg
    className={`calendar-log-icon${label ? `--${label}` : ''}`}
    width="18"
    height="18"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    {children}
  </svg>
)

const Log = ({ count, day, slot, defaultAvailability, refresh }) => {
  const dialog = useContext(DialogContext);

  const log = getLog(day, slot);

  const [
    availability,
    setAvailability
  ] = useState(0);

  const [
    note,
    setNote
  ] = useState('');

  const [
    hasLogNote,
    setHasLogNote
  ] = useState(false);

  const [
    logId,
    setLogId
  ] = useState(null);

  useEffect(() => {
    const myLogId = getLogId(day);

    if (myLogId) {
      setLogId(myLogId);
    }
    if (log) {
      setAvailability(Number(log.availability ? log.availability : defaultAvailability[slot]));
      setNote(log.note);
      if (log.note) {
        setHasLogNote(true);
      }
    } else {
      setAvailability(Number(defaultAvailability[slot]));
    }
  }, [log]);


  const onClick = () => {
    dialog.openWith({
      heading: 'Add Memo',
      content: (<LogForm
        date={day.date}
        availability={availability}
        note={note}
        slot={slot}
        logId={logId}
        onSuccess={refresh} />)
    });
  }

  const capacity = parseInt(availability, 10) - count

  return (
    <div className={`calendar__cell calendar__cell--${day.today ? 'today' : day.past ? 'past' : 'future'} calendar-log calendar-appt-${day.day}`}>
      <Availability capacity={capacity} availability={availability} />
      <div className={`calendar-log-icon calendar-log-icon__${dialog.isOpen ? 'is-open' : 'is-closed'}`} onClick={onClick}>
        <SvgIcon label="toggle" width="18" height="18">
          <path fill="#8C95AC" d="M3,12 C3,7.02943725 7.02943725,3 12,3 C16.9705627,3 21,7.02943725 21,12 C21,16.9705627 16.9705627,21 12,21 C7.02943725,21 3,16.9705627 3,12 Z M14.9545049,9.70450487 L12,12.6590097 L9.04549513,9.70450487 C8.6061553,9.26516504 7.8938447,9.26516504 7.45450487,9.70450487 C7.01516504,10.1438447 7.01516504,10.8561553 7.45450487,11.2954951 L11.2045049,15.0454951 C11.6438447,15.484835 12.3561553,15.484835 12.7954951,15.0454951 L16.5454951,11.2954951 C16.984835,10.8561553 16.984835,10.1438447 16.5454951,9.70450487 C16.1061553,9.26516504 15.3938447,9.26516504 14.9545049,9.70450487 Z" />
        </SvgIcon>
        <SvgIcon width="18" height="18">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path fill={hasLogNote ? '#4E97E8' : '#8C95AC'} d="M5,3 L5,21 L14,21 L19,16 L19,3 L5,3 Z M8,7 L16,7 L16,6 L8,6 L8,7 Z M8,10 L16,10 L16,9 L8,9 L8,10 Z M8,13 L16,13 L16,12 L8,12 L8,13 Z M8,16 L14,16 L14,15 L8,15 L8,16 Z" />
          </g>
        </SvgIcon>
      </div>
    </div>
  )
}

export default Log
